import { useEffect, useRef, useState, forwardRef, useImperativeHandle } from 'react';
import { instance } from '@viz-js/viz';
import useClickOutside from '../../components/useOutsideClick';
//@ts-ignore
import Papa from 'papaparse'

const scrollName = 'family-treescrollgen3'
interface scrollJSON {
    [key: string]: any
}
let scrollJSON: scrollJSON | null = null

interface Props {
    targetScroll: string,
    filterFamily: string,
    filterExec: string,
    filterPledgeClass: string,
    setFilterFamily: React.Dispatch<React.SetStateAction<string>>,
    setFilterExec: React.Dispatch<React.SetStateAction<string>>,
    setFilterPledgeClass: React.Dispatch<React.SetStateAction<string>>,
}

export interface FullTreeRef {
    adjustTreeBrightness: (brightness: number, opacity: number) => void
}

const FullTree = ({targetScroll, filterFamily, filterExec, filterPledgeClass, setFilterFamily, setFilterExec, setFilterPledgeClass}: Props) => {
    const [graphData, setGraphData] = useState<string | null>(null)
    const [isLoaded, setIsLoaded] = useState<boolean>(false)
    const [nodePos, setNodePos] = useState([0, 0])
    const [scroll, setScroll] = useState<String | null>(null)
    const [darkenedTree, setDarkenedTree] = useState<Boolean>(false)
    const svgRef = useRef<SVGSVGElement | null>(null)

    //useClickOutside(svgRef.current?.querySelectorAll('g.node'), () => {
        //console.log('Clicked outside the tree')
        //setDarkenedTree(false)
    //})

    useClickOutside(svgRef, 'tree-div', () => {
        console.log('clicked outside.')
        adjustTreeBrightness(1, 1)
        setFilterExec('')
        setFilterFamily('')
        setFilterPledgeClass('')
    })
    
    // FILTER LOGIC
    useEffect(() => {
        if (svgRef.current && scrollJSON) {

            // AND-based filter
            if (filterExec || filterFamily || filterPledgeClass) { 
                // Begin by dimming tree
                adjustTreeBrightness(.8, .5) 
                // Apply filters
                console.log(`Exec: ${filterExec} Family: ${filterFamily}, PC: ${filterPledgeClass} filter(s) selected...`)
                svgRef.current!.querySelectorAll('g.node').forEach(node => {
                    if (!node.id.includes('node') 
                        && (filterFamily == 'nofilter' || scrollJSON![node.id]['Family'].toLowerCase().trim() == filterFamily)
                        && (filterPledgeClass == 'nofilter' || scrollJSON![node.id]['Nom. Pledge Class'].toLowerCase().trim() == filterPledgeClass)
                    ) {
                        if (filterExec == 'nofilter') {

                        } else if (filterExec == 'prytanis' && scrollJSON![node.id]['Prytanis?'].toLowerCase().trim() == 'yes') {
                            adjustNodeBrightness(1, 1, node)
                        } else if (filterExec == 'hegemon' && scrollJSON![node.id]['Heg?'].toLowerCase().trim() == 'yes') {
                            adjustNodeBrightness(1, 1, node)
                        }
                    }
                })
            }

            /*
            // Exec filter
            if (filterExec) {
                // This should be refactored so that each member has one column with their exec positions in it...current implementation requires switch
                switch(filterExec.toLowerCase()) {
                    case 'prytanis':
                        console.log('Prytanis filter selected...')
                        svgRef.current!.querySelectorAll('g.node').forEach(node => {
                            if (scrollJSON) {
                                if (!node.id.includes('node') && scrollJSON[node.id]['Prytanis?'].toLowerCase().trim() === 'yes') {
                                    console.log(`#${scrollJSON![node.id]['Scroll']} ${scrollJSON![node.id]['Last Name']} was a prytanis!`)
                                    adjustNodeBrightness(1, 1, node)
                                }
                            }
                        })
                        break
                    case 'hegemon':
                        console.log('Hegemon filter selected...')
                        svgRef.current!.querySelectorAll('g.node').forEach(node => {
                            if (scrollJSON) {
                                if (!node.id.includes('node') && scrollJSON[node.id]['Heg?'].toLowerCase().trim() === 'yes') {
                                    console.log(`#${scrollJSON![node.id]['Scroll']} ${scrollJSON![node.id]['Last Name']} was a hegemon!`)
                                    adjustNodeBrightness(1, 1, node)
                                }
                            }
                        })
                        break
                }                
            }
            
            // Family filter
            if (filterFamily) {
                console.log(`${filterFamily} filter selected...`)
                svgRef.current!.querySelectorAll('g.node').forEach(node => {
                    if (!node.id.includes('node') && scrollJSON![node.id]['Family'].toLowerCase().trim() == filterFamily) {
                        adjustNodeBrightness(1, 1, node)
                    }
                })
            }
            
            // Pledge class filter
            if (filterPledgeClass) {
                console.log(`${filterPledgeClass} filter selected...`)
                svgRef.current!.querySelectorAll('g.node').forEach(node => {
                    if (!node.id.includes('node') && scrollJSON![node.id]['Nom. Pledge Class'].toLowerCase().trim() == filterPledgeClass) {
                        adjustNodeBrightness(1, 1, node)
                    }
                })
            }
            */
        }
    }, [filterExec, filterFamily, filterPledgeClass])

    const fetchGraph = async () => {
        if (graphData) return
        try {
            const dotfilePath = `https://server.duplantis.org/fraternity/tree`
            const scrollPath = `/scrollgen3.csv`
            const response = await fetch(dotfilePath, { headers: { 'jwt-token': localStorage.getItem('jwt-token') || '' } })
            const scrollResponse = await fetch(process.env.PUBLIC_URL + scrollPath, /*{ headers: { 'jwt-token': localStorage.getItem('jwt-token') || '' } }*/)
            const scrollText = await scrollResponse.text()
            const scrollArray = Papa.parse(scrollText, { header: true }).data
            scrollJSON = Object.fromEntries(scrollArray.map((row: any) => [row.Scroll, row]))
            //console.log(scrollJSON)

            if (!response.ok) { console.error(`Failed to fetch graph data: ${response.statusText}`) }
            
            return await response.text()
        } catch(e) {console.error(e)}
    }

    const graphvizRender = async (graphData: string) => {
        //console.log(`Attempting to render: ${graphData}`)
        const viz = await instance()
        const svgElement = await viz.renderSVGElement(graphData)
        if (svgRef.current) {
            svgRef.current.innerHTML = svgElement.innerHTML


            const bbox = svgRef.current.getBBox()
            if (bbox.height == 0) {
                console.error(`the bbox has a height of ${bbox.height}`)
            }

            svgRef.current!.setAttribute('viewBox', `${bbox.x} ${bbox.y} ${bbox.width} ${bbox.height}`)
            svgRef.current!.setAttribute('width', `${bbox.width}`);
            svgRef.current!.setAttribute('preserveAspectRatio', 'xMidYMid meet');
            setIsLoaded(true)
            svgRef.current.querySelectorAll('g.node').forEach(node => {
                node.addEventListener('click', async () => {
                    // For each node, select its children and darken them
                    //await setDarkenedTree(true)
                    const lineageScrolls = await getLineageScrolls(graphData, node.id)
                    if (scrollJSON) {
                        //(node as HTMLElement).style.filter = 'brightness(0.75)'
                        adjustTreeBrightness(0.8, 0.5)
                        svgRef.current?.querySelectorAll('g.node').forEach(node => {
                            if (lineageScrolls.includes(node.id)) {
                                adjustNodeBrightness(1, 1, node)
                            }
                        })
                        adjustNodeBrightness(1, 1, node)
                        
                        setScroll(node.id)
                        //alert(`Node clicked: ${node.id.trim()}: ${scrollJSON[node.id]["Last Name"]}`)
                    }
                });
            });
        }
    }

    const graph = async () => {
        if (svgRef) {
            const graphResponse = await fetchGraph()
            setGraphData(graphResponse ?? null)
            if (graphResponse) {
                if (!svgRef.current) return
                try {
                    console.log("Rendering graph...")
                    svgRef.current.innerHTML = '' // Clear previous content
                    await graphvizRender(graphResponse)
                    console.log("Completed rendering graph.")
                    
                } catch (e) {
                    console.error("ERROR during renderGraph():", e)
                }
            } else { console.error('Failed to fetch graph data.') }
        }
    }//

    const adjustTreeBrightness = (brightness: number, opacity: number) => {
        if (svgRef.current) {
            svgRef.current?.querySelectorAll('g.node').forEach(node => {
                (node.querySelectorAll('*').forEach(child => {
                    (child as HTMLElement).style.transition = 'filter 0.5s ease, -webkit-filter 0.5s ease';
                    (child as HTMLElement).style.transition = 'opacity 0.5s ease';
                    (child as HTMLElement).style.filter = `brightness(${brightness})`;
                    (child as HTMLElement).style.opacity = `${opacity}`
                }))
            })
        }
    }

    const adjustNodeBrightness = (brightness: number, opacity: number, node: Element) => {
        node.querySelectorAll('*').forEach(child => {
            (child as HTMLElement).style.transition = 'filter 0.3s ease-in-out';
            (child as HTMLElement).style.transition = 'opacity 0.3s ease-in-out';
            (child as HTMLElement).style.filter = 'brightness(1)';
            (child as HTMLElement).style.opacity = '1'
        });
    }

    // Darkening effect
    useEffect(() => {
        adjustTreeBrightness(0.9, 0.6)
        if (!darkenedTree) {
            adjustTreeBrightness(1, 1)
        }
    }, [darkenedTree])

    useEffect(() => {
        if (svgRef.current) {
            graph()
        }
    }, [targetScroll])

    return (<>
        <svg id='family-tree-svg' ref={svgRef} className={`pl-8 pr-8 max-h-full transition-opacity duration-1000 ease-in-out ${isLoaded ? 'opacity-100' : 'opacity-0'}`}></svg>
    </>);
}

export default FullTree

function getLineageScrolls(graph: any, targetScroll: string) {
    let graphRows = removeDuplicates(graph)
    // create array with targetScroll
    let upperLineageScrolls = [targetScroll]
    let lowerLineageScrolls = [targetScroll]
    
    // Iterate through the cleaned dot file row by row downwards
    //console.log(`--- starting with lower ${lowerLineageScrolls} ---`)
    for (let i = 0; i < graphRows.length; i++) {
        let newLineageScroll: string = ''
        //let knownLineageScroll: string = ''
        // Check each known lower lineage scroll against the row
        for (let j = 0; j < lowerLineageScrolls.length; j++) {
            if (graphRows[i].trim().startsWith(`${lowerLineageScrolls[j]} ->`)) {
                const knownLineageScroll = lowerLineageScrolls[j]
                //console.log(`"${graphRows[i].trim()} starts with ${knownLineageScroll}"`)
                newLineageScroll = graphRows[i].slice(knownLineageScroll.length + 5)
                //console.log(`Adding ${newLineageScroll} to lower: ${lowerLineageScrolls}`)
                break
            }
        }
        if (newLineageScroll !== '' && Number(targetScroll) < Number(newLineageScroll)) { lowerLineageScrolls.push(newLineageScroll) }
    }

    // Moving upwards
    //console.log(`--- starting with upper ${upperLineageScrolls} ---`)
    for (let i = graphRows.length - 1; i > 0; i--) {
        let newLineageScroll: string = ''
        //let knownLineageScroll: string = ''
        // Check each known upper lineage scroll against the row
        for (let j = 0; j < upperLineageScrolls.length; j++) {
            if (graphRows[i].includes(`-> ${upperLineageScrolls[j]}`)) { 
                const knownLineageScroll = upperLineageScrolls[j]
                //console.log(`"${graphRows[i].trim()} ends with ${knownLineageScroll}"`)
                newLineageScroll = graphRows[i].slice(1, graphRows[i].search(' -> '))
                //console.log(`Adding ${newLineageScroll} to upper: ${upperLineageScrolls}`)
                break
            }
        }
        if (newLineageScroll !== '') {upperLineageScrolls.push(newLineageScroll) }
    }
    const lineageScrolls = lowerLineageScrolls.concat(upperLineageScrolls.slice(1))
    return lineageScrolls
}

// removing duplicates
function removeDuplicates(graph: any) {
    let graphRows = graph.split('\n')
    for (let i = 0; i < graphRows.length; i++) {
        if (graphRows[i+1] == graphRows[i]) {
            graphRows[i] = ''
        }
    }
    return graphRows.filter((row: string) => row !== '')
}

// Outside click collapse handler
/*
function useClickOutsideSVG(svg: any) {
    useEffect(() => {
        //Invoke Function onClick outside of element
        function handleClickOutside(event: any) {
            if (svg.current && !svg.current.contains(event.target)) {
                console.log('clicked outside...')
                adjustTreeBrightness(1, 1)
            }
        }
        // Bind
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // dispose
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [svg]);
}
*/