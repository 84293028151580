import { useEffect } from "react";

function useClickOutside(ref: any, parent: string, onClickOutside: any,) {
  const parentDiv = document.getElementById(parent)
  useEffect(() => {
    function handleClickOutside(event: Event) {
      if (ref.current && !ref.current.contains(event.target) || ref.current === event.target) {
        onClickOutside(); // Call the outside click handler
      }
    }

    // Add event listener to detect clicks
    parentDiv?.addEventListener("pointerdown", handleClickOutside);

    return () => {
      // Clean up event listener
      parentDiv?.removeEventListener("pointerdown", handleClickOutside);
    };
  }, [ref, onClickOutside]);
}

export default useClickOutside;
