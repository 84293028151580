import { FormEventHandler, useEffect, useState, useRef } from "react"
import { useNavigate } from "react-router-dom"
import NavBar from "../../components/navigation/NavBar"
import FullTree, { FullTreeRef } from "./fulltree"
//@ts-ignore
import houseplateLogo from "../fraternity/TKE_brown-transp.png"

export default function Fraternity() {
    const nav = useNavigate()
    const [token, setToken] = useState<string>('')
    const [userData, setUserData] = useState<UserData | null>(null)

    // Filter states
    const [filterExec, setFilterExec] = useState<string>('')
    const [filterFamily, setFilterFamily] = useState<string>('')
    const [filterPledgeClass, setFilterPledgeClass] = useState<string>('')

    interface UserData {
        safehouseKey: string;
        decodedToken: {
            scroll: string;
            lastname: string;
        }
    }

    // Authentication
    useEffect(() => {
        const token = localStorage.getItem('jwt-token') || ''
        setToken(token)
        if (!token) {
            nav('/fraternity/login')
        } else {
            fetch('https://server.duplantis.org/fraternity', {
                headers: { 'jwt-token': token },
            })
            .then((res) => {
                if (!res.ok) {
                    throw new Error(`HTTP error! status: ${res.status}`)
                }
                return res.json()
            })
            .then((data) => {
                setUserData(data)
            })
            .catch((err) => console.error(err))
        }
    }, [])

    
    function logout() {
        setToken('')
        localStorage.removeItem('jwt-token')
        nav('/fraternity/login')
    }

    function setFilter(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault()
        const form = e.target as HTMLFormElement
        const formData = new FormData(form)
        const formJSON = Object.fromEntries(formData.entries())
        console.log(formJSON['selectExec'])
        setFilterExec(`${formJSON['selectExec']}`)
        setFilterFamily(`${formJSON['selectFamily']}`)
        setFilterPledgeClass(`${formJSON['selectPledgeClass']}`)
    }
    
    return (<>
        <NavBar sessionUser={userData?.decodedToken.scroll} />
        <div id='body-div' className="min-h-full w-full pt-16 bg-orange-100/75 flex items-center justify-center flex-col">
            <div className="p-2 mt-4">
                <p className="flex w-full justify-center font-semibold text-orange-950/75 text-lg md:text-2xl">Third Generation (Fall '91 - Present)</p>
                <p className="flex w-full justify-center font-semibold text-orange-950/50">503 out of 537 fraters recorded (~94%)</p>
            </div>
            <div className="invisible md:visible h-0 md:h-max">
                <FilterMenu filterFunction={setFilter} />
            </div>
            <div id='tree-div' className="flex w-full p-4 justify-center">
                <FullTree targetScroll='' filterExec={filterExec} filterFamily={filterFamily} filterPledgeClass={filterPledgeClass} setFilterExec={setFilterExec} setFilterFamily={setFilterFamily} setFilterPledgeClass={setFilterPledgeClass} />
            </div>
            <div className="flex flex-row items-center w-full justify-center">
                <p className="text-xs md:text-lg w-1/3 text-right text-orange-950/50">Last updated January 12th, 2025</p>
                <img className="h-12 md:h-20" src={houseplateLogo} alt="" />
                <p className="text-xs md:text-lg w-1/3 text-left text-orange-950/50">Started work in Spring 2022</p>
            </div>
            <div className="visible md:hidden">
                <FilterMenu filterFunction={setFilter} />
            </div>
            <div className="z-1 sm:static md:top-0 md:absolute md:right-0 md:mr-4 flex flex-row h-16">
                <p className="m-auto mr-4 text-1xl text-orange-950/75 font-semibold">{`${userData?.decodedToken.lastname || ''} #${userData?.decodedToken.scroll || ''}`}</p>
                <button className="w-24 ml-auto mr-auto bg-orange-950/75 hover:bg-orange-950/80 active:bg-orange-950/90 text-white py-2 px-4 rounded-lg font-semibold m-auto" onClick={logout}>Logout</button>
            </div>
        </div>
        
    </>)
}

const FilterMenu = ({filterFunction}: {filterFunction: FormEventHandler}) => {
    const svgRef = document.getElementById('family-tree-svg')

    const adjustTreeBrightness = (brightness: number, opacity: number) => {
        console.log(`Attempting to brighten ${svgRef}`)
        if (svgRef) {
            svgRef?.querySelectorAll('g.node').forEach(node => {
                (node.querySelectorAll('*').forEach(child => {
                    (child as HTMLElement).style.transition = 'filter 0.5s ease, -webkit-filter 0.5s ease';
                    (child as HTMLElement).style.transition = 'opacity 0.5s ease';
                    (child as HTMLElement).style.filter = `brightness(${brightness})`;
                    (child as HTMLElement).style.opacity = `${opacity}`
                }))
            })
        }
    }

    return(<>
        <form method='post' onSubmit={filterFunction} className="flex flex-col md:flex-row w-5/9 md:w-max font-semibold space-y-2 md:space-y-0 md:space-x-2 font-semibold text-orange-950/75 text-xs bg-orange-50/75 border border-orange-950/75 p-4 rounded-lg mt-2 mb-2">
            
            <select name="selectExec" id="" defaultValue='nofilter' className="border border-gray-300 rounded-lg p-2 focus:ring-2 focus:ring-blue-500 focus:outline-none bg-white w-full md:w-max">
                <option value="nofilter" className="text-orange-950/25">Exec. Position</option>
                <option value="prytanis">Prytanis</option>
                <option value="hegemon">Hegemon</option>
            </select>
            
            <select name="selectFamily" id="" defaultValue='nofilter' className="border border-gray-300 rounded-lg p-2 focus:ring-2 focus:ring-blue-500 focus:outline-none bg-white w-full md:w-max">
                <option value="nofilter" className="text-orange-950/25">Family</option>
                <option value="4D">4D</option>
                <option value="blondies">Blondies</option>
                <option value="blue bombers">Blue Bombers</option>
                <option value="degens">Degens</option>
                <option value="goodfellas">Goodfellas</option>
                <option value="grafenburgs">Grafenburgs</option>
                <option value="greatest american heroes">Greatest American Heroes</option>
                <option value="hogshead">Hogshead</option>
                <option value="killa bees">Killa Bees</option>
                <option value="los lobos">Los Lobos</option>
                <option value="misfits">Misfits</option>
                <option value="oompa">Oompa</option>
                <option value="playboys">Playboys</option>
                <option value="pwok">PWOK</option>
                <option value="red lotus">Red Lotus</option>
                <option value="stby">STBY</option>
                <option value="team a-frame">Team A-Frame</option>
                <option value="tempe mafia">Tempe Mafia</option>
                <option value="wu-tang/yi-tang">Wu-Tang/Yi-Tang</option>
                <option value="unaffiliated">Unaffiliated</option>
            </select>
            <select name="selectPledgeClass" id="" defaultValue='nofilter' className="border border-gray-300 rounded-lg p-2 focus:ring-2 focus:ring-blue-500 focus:outline-none bg-white w-full md:w-max">
                <option value="nofilter" className="text-orange-950/25">Pledge Class</option>
                <option value="founding father">Founding Father</option>
                <option value="re-founding father">Re-founding Father</option>
                <option value="transfer">Transfer</option>
                <option value="honorary">Honorary</option>
                <option value="alpha">Alpha</option>
                <option value="beta">Beta</option>
                <option value="gamma">Gamma</option>
                <option value="delta">Delta</option>
                <option value="epsilon">Epsilon</option>
                <option value="zeta">Zeta</option>
                <option value="eta">Eta</option>
                <option value="theta">Theta</option>
                <option value="iota">Iota</option>
                <option value="kappa">Kappa</option>
                <option value="lambda">Lambda</option>
                <option value="mu">Mu</option>
                <option value="nu">Nu</option>
                <option value="xi">Xi</option>
                <option value="omicron">Omicron</option>
                <option value="pi">Pi</option>
                <option value="rho">Rho</option>
                <option value="sigma">Sigma</option>
                <option value="tau">Tau</option>
                <option value="upsilon">Upsilon</option>
                <option value="phi">Phi</option>
                <option value="chi">Chi</option>
                <option value="psi">Psi</option>
                <option value="omega">Omega</option>
                <option value="alpha alpha">Alpha Alpha</option>
                <option value="alpha beta">Alpha Beta</option>
                <option value="alpha gamma">Alpha Gamma</option>
                <option value="alpha delta">Alpha Delta</option>
                <option value="alpha epsilon">Alpha Epsilon</option>
                <option value="beta iota">Beta Iota</option>
                <option value="beta kappa">Beta Kappa</option>
                <option value="beta lambda">Beta Lambda</option>
                <option value="beta mu">Beta Mu</option>
                <option value="beta nu">Beta Nu</option>
                <option value="beta xi">Beta Xi</option>
                <option value="beta omicron">Beta Omicron</option>
                <option value="beta pi">Beta Pi</option>
                <option value="beta rho">Beta Rho</option>
                <option value="beta sigma">Beta Sigma</option>
                <option value="beta tau">Beta Tau</option>
                <option value="beta upsilon">Beta Upsilon</option>
                <option value="beta phi">Beta Phi</option>
                <option value="beta chi">Beta Chi</option>
                <option value="beta psi">Beta Psi</option>
                <option value="beta omega">Beta Omega</option>
                <option value="gamma alpha">Gamma Alpha</option>
                <option value="gamma beta">Gamma Beta</option>
                <option value="gamma gamma">Gamma Gamma</option>
                <option value="gamma delta">Gamma Delta</option>
                <option value="gamma epsilon">Gamma Epsilon</option>
                <option value="gamma zeta">Gamma Zeta</option>
                <option value="gamma eta">Gamma Eta</option>
                <option value="gamma theta">Gamma Theta</option>
                <option value="gamma iota">Gamma Iota</option>
                <option value="gamma kappa">Gamma Kappa</option>
                <option value="gamma lambda">Gamma Lambda</option>
                <option value="gamma mu">Gamma Mu</option>
                <option value="gamma nu">Gamma Nu</option>
                <option value="gamma xi">Gamma Xi</option>
                <option value="gamma omicron">Gamma Omicron</option>
                <option value="gamma rho">Gamma Rho</option>
                <option value="gamma sigma">Gamma Sigma</option>
                <option value="gamma tau">Gamma Tau</option>
                <option value="gamma upsilon">Gamma Upsilon</option>
                <option value="gamma phi">Gamma Phi</option>
                <option value="gamma chi">Gamma Chi</option>
                <option value="gamma psi">Gamma Psi</option>
                <option value="gamma omega">Gamma Omega</option>
                <option value="delta alpha">Delta Alpha</option>
                <option value="delta beta">Delta Beta</option>
                <option value="delta gamma">Delta Gamma</option>
                <option value="delta delta">Delta Delta</option>
                <option value="delta epsilon">Delta Epsilon</option>
            </select>
            <div className="space-x-2">
                <button type='submit' className="w-24 ml-auto mr-auto bg-orange-950/75 hover:bg-orange-950/80 active:bg-orange-950/90 text-white py-2 px-4 rounded-lg font-semibold m-auto">Filter</button>
                <button type='reset' onClick={() => adjustTreeBrightness(1,1)} className="w-24 ml-auto mr-auto bg-orange-950/75 hover:bg-orange-950/80 active:bg-orange-950/90 text-white py-2 px-4 rounded-lg font-semibold m-auto">Reset</button>
            </div>
        </form>
    </>)
}